<template>
  <div>
    <b-card class="position-relative" :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
      <div class="flex flex-col font-rubik">
        <div class="flex flex-col md:flex-row justify-between mb-3">
          <h3 class="text-gray-900 text-2xl font-semibold font-poppins">{{ $t(locale.activatedIntegration) }}</h3>
          <div class="flex items-center  gap-3">
            <span class="text-base">{{ $t('IntegrationCard.filter') }}</span>
            <span>
                            <b-form-select
                                id="perPageSelect"
                                size="sm"
                                inline
                            >
                                <b-form-select-option
                                    v-for="(
                                        option, index
                                    ) in filterSelect"
                                    :key="index"
                                    :value="
                                        option.value
                                    "
                                    v-text="
                                        $t(option.label)
                                    "
                                />
                            </b-form-select>
                        </span>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-10 mb-40">
          <div v-for="(item, i) in integrations" :key="i" class="flex flex-col">
            <div class="mb-3" style="height: 180px !important;;width: 221px">
              <img v-if="item.image" :src="item.image" alt="">
            </div>
            <div class="mb-1 mt-1">
              Modul: {{ item[integrationName] }}
            </div>
            <div v-if="item.club_account_organisation_number" class="mb-1 mt-1">
              Klubbkonto, org.nr: {{ item.club_account_organisation_number }}
            </div>
            <div v-if="item.club_account_name" class="mb-1 mt-1">
              Klubbkonto, namn: {{ item.club_account_name }}
            </div>
            <button v-if="!item.connected" @click="connectIntegration(item)"
                    class="flex c-p-3 text-center focus:outline-none mt-1 bg-theme-sidebar text-white whitespace-nowrap rounded-md">
                            <span v-if="item.loadingStatus">
                                <b-spinner
                                    small
                                    type="grow"
                                />
                                {{ $t('Message.loading') }}...
                            </span>

              <span
                  class="whitespace-nowrap text-center w-full text-base"
                  v-else
                  v-text="
                                    $t(locale.connect)
                                "
              />
            </button>
            <button v-else
                    class="flex c-p-3 text-center focus:outline-none bg-green-500 text-white whitespace-nowrap rounded-md">
                            <span
                                class="whitespace-nowrap text-center w-full text-base"
                                v-text="
                                    $t(locale.connected)
                                "
                            />
            </button>
          </div>
        </div>
      </div>
    </b-card>

    <CustomNoHeaderModalVue v-if="openModal" width="w-1/2 md:w-1/4" :title="''" @handleClose="openModal = false"
                            :close="!true">

      <div class="flex flex-col" slot="body">
        <div class="flex justify-center">
          <img src="@/assets/images/pages/connect.png" alt="">
        </div>
        <div class="flex mb-">
          <p class="text-center">
            {{
              $t(locale.integrationTitle, {
                integration: selectedData[integrationName]
              })
            }}
          </p>
        </div>
        <div class="flex justify-between mt-1">
          <b-button
              @click="openModal = false"
              variant="outline-secondary"
              v-text=" $t('Message.cancel')"
          />
          <b-button
              variant="primary"
              :disabled="modalStatus"
              @click="makeConnection"
              v-text="modalStatus ? $t('Message.loading') : $t(locale.proceed)"
          />
        </div>
      </div>
    </CustomNoHeaderModalVue>

  </div>
</template>

<script>
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import {billingMixins} from '@/mixins/billingMixins';
import {integrationMixins} from '@/mixins/integrationMixins'
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import {mapGetters} from "vuex";
import mdAccessDenied from "@core/components/permission/mdAccessDenied";

export default {
  name: 'ArticleConfigView',
  mixins: [getSubscribersMixins, billingMixins, integrationMixins],
  components: {CustomNoHeaderModalVue, mdAccessDenied},
  data() {
    return {
      accessDenied: false,
      saving: false,
      showRequest: false,
      activeModule: [],
      openModal: false,
      modalStatus: false,
      selectedData: {},
      integrations: [],
      filterSelect: [
        {
          value: 'all',
          label: 'All'
        }
      ],
      locale: {
        save: 'ContractConfig.save',
        integrationTitle: 'IntegrationCard.title',
        proceed: 'IntegrationCard.proceed',
        connect: 'IntegrationCard.connect',
        connected: 'IntegrationCard.connected',
        activatedIntegration: 'IntegrationCard.activatedIntegration',

      }
    };
  },
  computed: {
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    integrationName() {
      return this.GET_LOCALE === 'sv' ? 'swe_name' : 'eng_name';
    },
    features() {
      return this.GET_LOCALE === 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  async mounted() {
    this.getPageAccess()
    await this.getIntegration()
  },
  methods: {
    async getIntegration() {
      const data = await this.getIntegrations()
      this.integrations = []
      const {active_modules, disconnected_modules} = data.data
      active_modules.map(el => {
        this.integrations.push({
          ...el,
          connected: true
        })
      })
      disconnected_modules.map(el => {
        this.integrations.push({
          ...el,
          connected: false,
          loadingStatus: false
        })
      })
    },
    makeConnection() {
      this.modalStatus = true
      const URL = this.selectedData.connect_endpoint.replace('/v1', '') + `?uuid=${this.selectedData.club_account_uuid}`
      this.$useJwt.useBaseURLOnly(`${URL}`)
          .then(res => {
            this.modalStatus = false
            // window.open(res.data.auth_link, 'self')
            window.location.replace(res.data.auth_link);
          })
          .catch(err => {
            this.modalStatus = false
            if (err.response.data.error) {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data.error,
                  'AlertTriangleIcon',
                  'danger'
              );
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    },
    connectIntegration(data) {
      this.selectedData = data
      this.openModal = true
    },
    getPageAccess() {
      this.getIntegration().catch(err => {
        if (err.response.status === 403) {
          this.accessDenied = true
        } else {
          this.accessDenied = false
        }
      })
    }
  },

};
</script>
<style>

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


</style>
