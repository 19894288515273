<template>
  <div class="flex flex-col">
    <div class="d-flex justify-content-start">
      <div
          class="bg-secondaryColor d-flex bigGap align-items-center tabWrapper"
      >
                <span v-for="(data, index) in tabs" :key="index">
                    <b-button
                        v-if="data.active"
                        variant="light"
                        v-text="data.label"
                    />

                    <p
                        v-else
                        @click="switchTabs(index)"
                        class="mb-0 text-light"
                        role="button"
                        v-text="data.label"
                    />
                </span>
      </div>
    </div>
    <div class="flex flex-col mt-2">
      <KeepAlive>
        <!-- <transition name="zoom-fade" mode="out-in"> -->
        <component :is="currentComponent"/>
        <!-- </transition> -->
      </KeepAlive>
    </div>
  </div>
</template>

<script>
import IntegrationMainVue from '@/@core/components/integration/IntegrationMain.vue';
import IntegrationModuleVue from '@/@core/components/integration/IntegrationModule.vue';

export default {
  name: 'Integration',
  components: {
    IntegrationMainVue,
    IntegrationModuleVue
  },
  data() {
    return {
      currentComponent: IntegrationMainVue,
      tabs: [
        {
          label: 'Mina moduler/integrationer',
          components: 'IntegrationMainVue',
          active: true,
        },
        {
          label: 'Moduler/Integrationer',
          components: 'IntegrationModuleVue',
          active: false,
        },
      ],
    }
  },
  methods: {
    switchTabs(index) {
      this.tabs.find(item => item.active == true).active = false;

      this.currentComponent = this.tabs[index].components;

      this.tabs[index].active = true;
    },
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    }
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    // this.getUserSubscriptions()
  }
};
</script>

<style></style>
