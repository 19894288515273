import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import {mapGetters} from "vuex";
export const integrationMixins = {
    data() {
        return {
            config: useAppConfig(),
            disableLoading: '',
        };
    },
    computed: {
        message() {
            return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
        },
        getLang() {
            return this.config.lang.value;
        },
        ...mapGetters({
            GET_LOCALE: 'appConfig/getLocale'
        })
    },
    methods: {
        getIntegrations() {
            return this.$useJwt.integrationModule();
        },
        getAllModules(dataParams) {
            return this.$useJwt.getAllModule({ params : dataParams });
        },
    },
};
