<template>
  <div>
    <b-card class="position-relative" :class="{'md-access-denied-height': accessDenied === true}">
      <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true"/>
      <div class="flex flex-col font-rubik">
        <div class="flex flex-col md:flex-row justify-between mb-1">
          <span class="text-lg">{{ $t('IntegrationCard.modulesText') }}</span>
          <div class="flex items-center  gap-3">
                        <span>
                            <b-form-input
                                id="perPageSelect"
                                size="sm"
                                placeholder="Sök"
                                @input="search"
                                inline
                            />
                        </span>
          </div>
        </div>
        <div class="flex flex-col md:flex-row justify-between mb-3">
          <span>{{ $t('IntegrationCard.notFound') }} <span @click="showRequest = true"
                                                           class="text-theme-teal cursor-pointer">{{
              $t('IntegrationCard.requestIntegration')
            }}</span></span>
          <div class="flex items-center  gap-3">
            <span class="text-base">{{ $t('IntegrationCard.filter') }}</span>
            <span>
                            <b-form-select
                                id="perPageSelect"
                                size="sm"
                                inline
                            >
                                <b-form-select-option
                                    v-for="(
                                        option, index
                                    ) in filterSelect"
                                    :key="index"
                                    :value="
                                        option.value
                                    "
                                    v-text="
                                        $t(option.label)
                                    "
                                />
                            </b-form-select>
                        </span>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-4 gap-5 mb-40">
          <div v-for="(item, i) in allModules.results" :key="i" class="flex flex-col">
            <div class="mb-1 position-relative">
              <span class="position-absolute right-1 cursor-pointer" v-on:click="openModuleDetail(item)"><svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-blue-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
              >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg></span>
              <img v-if="item.image" :src="item.image" alt="" style="width: 221px;height: 180px !important;">
              <span>{{ item[moduleName] }} - {{ item.monthly_add_on }} SEK exkl. moms</span>
            </div>
            <button @click="verifyModule(item, 'ADD')" :disabled="item.loadingStatus" v-if="!item.currentModule"
                    type="submit"
                    class="flex c-p-3 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md">
                            <span class="w-full flex justify-center items-center" v-if="item.loadingStatus">
                                <b-spinner
                                    small
                                    type="grow"
                                />
                                {{ $t('Message.loading') }}...
                            </span>

              <span
                  class="whitespace-nowrap text-center w-full text-base"
                  v-else
                  v-text="
                                    $t('Message.get')
                                "
              />
            </button>
            <button v-else @click="verifyModule(item, 'REMOVE')"
                    class="flex c-p-3 text-center focus:outline-none bg-red-500 text-white whitespace-nowrap rounded-md">
                            <span
                                class="whitespace-nowrap text-center w-full text-base"
                                v-text="
                                    $t('Message.remove')
                                "
                            />
            </button>
          </div>
        </div>
      </div>
    </b-card>

    <div v-if="showRequest">
      <b-card>
        <div class="flex flex-col">
          <h2 class="font-semibold text-xl text-gray-900 mb-1">Request an integration</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div class="flex flex-col md:flex-row md:gap-3">
              <div class="flex flex-col mb-1">
                <div class="mb-1">
                  <label for="" class="text-base mb-1">Your Name</label>
                  <div class="">
                    <b-form-input
                        name="login-email"
                        size="sm"
                        :placeholder="
                                                'Request Subject'
                                            "
                    />
                  </div>
                </div>
                <div>
                  <label for="" class="text-base mb-1">Contact Email</label>
                  <div class="">
                    <b-form-input
                        name="login-email"
                        size="sm"
                        :placeholder="
                                                'Request Subject'
                                            "
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="mb-1">
                  <label for="" class="text-base mb-1">App to integrate</label>
                  <div class="">
                    <b-form-input
                        name="login-email"
                        size="sm"
                        :placeholder="
                                                'Request Subject'
                                            "
                    />
                  </div>
                </div>
                <div>
                  <label for="" class="text-base mb-1">Phone Number</label>
                  <div class="">
                    <b-form-input
                        name="login-email"
                        size="sm"
                        :placeholder="
                                                'Request Subject'
                                            "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <label for="" class="text-base text-gray-800 mb-1">Integration description</label>
              <b-form-textarea
                  id="textarea"
                  placeholder=""
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
          <div class="flex justify-end mt-1">
            <button type="submit"
                    class="flex c-p-3 text-center focus:outline-none bg-theme-sidebar text-white whitespace-nowrap rounded-md">
                            <span v-if="saving">
                                <b-spinner
                                    small
                                    type="grow"
                                />
                                {{ $t('Message.loading') }}...
                            </span>

              <span
                  class="whitespace-nowrap text-center w-full text-base"
                  v-else
                  v-text="
                                    'Request integration'
                                "
              />
            </button>
          </div>
        </div>
      </b-card>
    </div>

    <CustomNoHeaderModalVue v-if="modalUserInput" width="w-full md:w-1/4" :title="''"
                            @handleClose="modalUserInput = false" :close="!true">
      <div class="flex flex-col p-1" slot="header">
        <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
            v-if="selectedModule.regarding_instance_type === 'ClubAccount'">Klubbkontodetaljer</h2>
        <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900" v-else>Användarkontodetaljer</h2>
      </div>
      <div class="flex flex-col p-1" slot="body">
        <!--        {{ selectedModule }}-->
        <!--        <div class="form-group" v-if="selectedModule.regarding_instance_type === 'ClubAccount'">-->
        <!--          <b-form-group>-->
        <!--            <label for="" class="text-lg text-gray-600">Current Club Account</label>-->
        <!--            <select class="custom-border w-full bg-white border-gray-300 focus:outline-none flex rounded-md c-p-3">-->
        <!--              <option :value="userData.current_account.uuid" selected>{{ userData.current_account.name }}</option>-->
        <!--            </select>-->
        <!--          </b-form-group>-->
        <!--        </div>-->
        <div class="form-group" v-if="selectedModule.regarding_instance_type === 'ClubAccount'">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <span v-for="(acc, i) in selectedAccount" :key="acc.uuid"
                              class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white">
                            <small>Club: {{ acc.name }}</small>
                            <span class="cursor-pointer" @click="removeData(i, 'CLUB')">
                                <feather-icon icon="Trash2Icon"/>
                            </span>
                        </span>
          </div>
          <b-form-group>
            <label for="" class="text-lg text-gray-600">Välj klubbkonton
              (<span>{{ selectedModule.remove ? 'Klubbkonton att ta bort' : 'Klubbkonton att lägga till' }}</span>)</label>
            <select v-model="accountSelect" @change="addData($event, 'CLUB')"
                    class="custom-border w-full bg-white border-gray-300 focus:outline-none flex rounded-md c-p-3">
              <option :value="item" v-for="item in otherClubAccount" :key="item.uuid">{{ item.name }}</option>
            </select>
          </b-form-group>
        </div>
        <div class="form-group" v-if="selectedModule.regarding_instance_type === 'User'">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <span v-for="(user, i) in selectedUsers" :key="user.uuid"
                              class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white">
                            <small>User: {{ user.full_name }}</small>
                            <span class="cursor-pointer" @click="removeData(i, 'USER')">
                                <feather-icon icon="Trash2Icon"/>
                            </span>
                        </span>
          </div>
          <b-form-group>
            <label for="" class="text-lg text-gray-600">Välj användare
              (<span>{{ selectedModule.remove ? 'användarkonton att ta bort' : 'användarkonton att lägga till' }}</span>)</label>
            <select v-model="userSelect" @change="addData($event, 'USER')"
                    class="custom-border w-full bg-white border-gray-300 focus:outline-none flex rounded-md c-p-3">
              <option :value="item" v-for="item in accountUsers" :key="item.uuid">{{ item.full_name }}</option>
            </select>
          </b-form-group>
        </div>
        <div class="form-group w-full">
          <b-button
              v-if="selectedModule.remove"
              size="md"
              class="w-full"
              variant="danger"
              v-text="processing ? $t('Message.loading') :  $t('Message.proceed')"
              @click="processModal('REMOVE')"
          />
          <b-button
              v-else
              size="md"
              class="w-full"
              variant="success"
              v-text="processing ? $t('Message.loading') :  $t('Message.proceed')"
              @click="processModal('ADD')"
          />
        </div>
      </div>
    </CustomNoHeaderModalVue>

    <CustomNoHeaderModalVue v-if="modalAddAccount" width="w-full md:w-2/4" :title="''"
                            @handleClose="modalAddAccount = false" :close="!true">
      <div class="flex flex-col p-1" slot="header"> 
        <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">Klubbkontodetaljer</h2>
      </div>

      <div class="flex flex-col p-1" slot="body">
        <div class="">
          <b-tabs pills class="bg-white">
            <b-tab :title="`Klubbkonto ${1+userIndex}`" v-for="(user_form,userIndex) in userForm" :key="userIndex">
              <b-card-text>
                <div class="form-group">
                  <b-form-group>
                    <label for="" class="text-lg text-gray-600">Företagsnamn</label>
                    <b-form-input
                        v-model="user_form.name"
                    />
                  </b-form-group>
                </div>
                <div class="form-group"
                     v-if="selectedModule.regarding_instance_type === 'ClubAccount' && !selectedModule.remove">
                  <b-form-group>
                    <label for="" class="text-lg text-gray-600">Organisationsnummer</label>
                    <b-form-input
                        v-model="user_form.number"
                    />
                  </b-form-group>
                </div>
                <div class="form-group">
                  <b-form-group>
                    <label for="" class="text-lg text-gray-600">Bokföringsprogram</label>
                    <select v-model="user_form.accounting_program"
                            class="custom-border w-full bg-white border-gray-300 focus:outline-none flex rounded-md c-p-3">
                      <option :value="item.be_name" v-for="(item, i) in accountingProgam" :key="i">{{
                          item[moduleName]
                        }}
                      </option>
                    </select>
                  </b-form-group>
                </div>
                <div class="form-group">
                  <div class="grid grid-cols-1 md:grid-cols-3 gap-1">
                        <span v-for="(user, i) in user_form.selected" :key="user.uuid"
                              class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white justify-content-between">
                            <small>User: {{ user.full_name }}</small>
                            <span class="cursor-pointer" @click="removeAccountData(i, 'USER',userIndex)">
                                <feather-icon icon="Trash2Icon"/>
                            </span>
                        </span>
                  </div>
                  <b-form-group>
                    <label for="" class="text-lg text-gray-600"> Användare som ska ha tillgång till kontot</label>
                    <select v-model="user_form.select" @change="addAccountData($event, 'USER',userIndex)"
                            class="custom-border w-full bg-white border-gray-300 focus:outline-none flex rounded-md c-p-3">
                      <option :value="item" v-for="item in accountUsers" :key="item.uuid">{{ item.full_name }}</option>
                    </select>
                  </b-form-group>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>

        </div>
        <div class="form-group w-full">
          <b-button
              size="md"
              class="w-full"
              variant="success"
              :disabled="processing"
              v-text="processing ? $t('Message.loading') :  $t('Message.proceed')"
              @click="processModal('ADD')"
          />
        </div>
      </div>
    </CustomNoHeaderModalVue>
    <CustomNoHeaderModalVue v-if="moduleModal" width="w-full md:w-1/4" :title="''"
                            @handleClose="moduleModal = false" :close="!true">
      <!--      <div class="flex flex-col p-1" slot="header">-->

      <!--      </div>-->

      <div class="flex flex-col p-1" slot="body">
        <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">
          {{ selectedModule[moduleName] }}
        </h2>
        <div class="">
          <p v-if="GET_LOCALE === 'sv'">{{ selectedModule.swe_description }}</p>
          <p v-else>{{ selectedModule.eng_description }}</p>
          <span>{{ $t('IntegrationCard.monthlyAddOnPrice') }} : {{
              selectedModule.monthly_add_on
            }} SEK exkl. moms</span>
        </div>
      </div>
    </CustomNoHeaderModalVue>

    <CustomNoHeaderModalVue width="w-full md:w-2/4" :title="''" v-if="modalAddUsers"
                            @handleClose="modalAddUsers = false" :close="!true">
      <div class="flex flex-col p-1" slot="header">
        <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">User Account Details</h2>
      </div>
      <div class="flex flex-col p-1" slot="body">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <span v-for="(user, i) in selectedUsers" :key="user.uuid"
                              class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white">
                            <small>User: {{ user.full_name }}</small>
                            <span class="cursor-pointer" @click="removeData(i, 'USER')">
                                <feather-icon icon="Trash2Icon"/>
                            </span>
                        </span>
        </div>
        <b-form-group>
          <label for="" class="text-lg text-gray-600">Select
            Users(<span>{{ selectedModule.remove ? 'Users To Remove' : 'Users To Add' }}</span>)</label>
          <select v-model="userSelect" @change="addData($event, 'USER')"
                  class="custom-border w-full bg-white border-gray-300 focus:outline-none flex rounded-md c-p-3">
            <option :value="item" v-for="item in accountUsers" :key="item.uuid">{{ item.full_name }}</option>
          </select>
        </b-form-group>
      </div>
    </CustomNoHeaderModalVue>
    <CustomNoHeaderModalVue v-if="showRequest" width="w-11/12 md:w-3/4" :title="''" @handleClose="showRequest = false"
                            :close="!true">
      <div class="flex flex-col p-1" slot="header">
        <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900">Request an integration</h2>
      </div>
      <div class="flex flex-col p-1" slot="body">
        <ValidationObserver ref="form">

          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div class="flex flex-col md:flex-row md:gap-3">
              <div class="flex flex-col mb-1">
                <ValidationProvider
                    #default="{errors}"
                    rules="required"
                    :name="'name'"
                >
                  <div class="mb-1">
                    <label for="" class="text-base mb-1">Your Name</label>
                    <div class="">
                      <b-form-input
                          v-model="requestForm.your_name"
                          name="login-email"
                          size="sm"
                          :placeholder="
                                                    'Name'
                                                "
                      />
                      <small class="text-danger" v-text="errors[0]"/>
                    </div>
                  </div>
                </ValidationProvider>
                <div>
                  <ValidationProvider
                      #default="{errors}"
                      rules="required|email"
                      :name="'email'"
                  >
                    <label for="" class="text-base mb-1">Contact Email</label>
                    <div class="">
                      <b-form-input
                          v-model="requestForm.contact_email"
                          name="login-email"
                          size="sm"
                          :placeholder="
                                                    'email'
                                                "
                      />
                    </div>
                    <small class="text-danger" v-text="errors[0]"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="mb-1">
                  <ValidationProvider
                      #default="{errors}"
                      rules="required"
                      :name="'name'"
                  >
                    <label for="" class="text-base mb-1">App to integrate</label>
                    <div class="">
                      <b-form-input
                          name="login-email"
                          size="sm"
                          v-model="requestForm.app_to_integrate"
                          :placeholder="
                                                    'app to integrate'
                                                "
                      />
                    </div>
                    <small class="text-danger" v-text="errors[0]"/>
                  </ValidationProvider>

                </div>
                <div>
                  <ValidationProvider
                      #default="{errors}"
                      rules="required"
                      :name="'name'"
                  >
                    <label for="" class="text-base mb-1">Phone Number</label>
                    <div class="">
                      <b-form-input
                          name="login-email"
                          size="sm"
                          v-model="requestForm.phone_number"
                          :placeholder="
                                                    'phone'
                                                "
                      />
                    </div>
                    <small class="text-danger" v-text="errors[0]"/>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <ValidationProvider
                  #default="{errors}"
                  rules="required"
                  :name="'name'"
              >
                <label for="" class="text-base text-gray-800 mb-1">Integration description</label>
                <b-form-textarea
                    id="textarea"
                    placeholder=""
                    v-model="requestForm.integration_description"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
                <small class="text-danger" v-text="errors[0]"/>
              </ValidationProvider>
            </div>
          </div>
          <div class="flex justify-end mt-1">
            <button @click="requestIntegration" type="submit" :disabled="requesting"
                    class="flex c-p-3 text-center focus:outline-none bg-theme-sidebar text-white whitespace-nowrap rounded-md">
                            <span class="w-full flex justify-center" v-if="requesting">
                                <b-spinner
                                    small
                                    type="grow"
                                />
                                {{ $t('Message.loading') }}...
                            </span>

              <span
                  class="whitespace-nowrap text-center w-full text-base"
                  v-else
                  v-text="
                                    'Request integration'
                                "
              />
            </button>
          </div>
        </ValidationObserver>

      </div>
    </CustomNoHeaderModalVue>
    <Modal
        id="activate-module-modal"
        :hideFooter="true"
        :title="$t('activateModule')"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ $t('aboutToActivate') }} {{ selectedModule[moduleName] }} {{ $t('forAdditional') }} {{ selectedModule.monthly_add_on }} {{ $t('SEKMonth') }} 
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('activate-module-modal')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="performModalAction"
        >
          <span
              v-text="sendingModalAction ? $t('Message.loading') : $t('activate')"
          />
        </b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import {billingMixins} from '@/mixins/billingMixins';
import {integrationMixins} from '@/mixins/integrationMixins'
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import debounce from 'lodash.debounce';
import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import {mapGetters} from "vuex";


export default {
  name: 'ModuleView',
  mixins: [getSubscribersMixins, billingMixins, integrationMixins],
  components: {
    ValidationProvider,
    ValidationObserver,
    CustomNoHeaderModalVue,
    mdAccessDenied
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      otherClubAccount: [],
      accountUsers: [],
      selectedUsers: [],
      selectedAccount: [],
      sendingModalAction: false,
      modalAddAccount: false,
      moduleModal: false,
      modalAddUsers: false,
      userSelect: '',
      accountSelect: '',
      accessDenied: false,
      requesting: false,
      showRequest: false,
      allModules: {results: []},
      filterSelect: [
        {
          value: 'all',
          label: 'All'
        }
      ],
      locale: {
        save: 'ContractConfig.save'
      },
      queryParams: {
        limit: 100
      },
      form: {
        name: '',
        number: '',
        accounting_program: '',
        selected: [],
        select: ''
      },
      userForm: [],
      userInput: {
        selected: []
      },
      userInputForm: [],
      saving: false,
      processing: false,
      requestForm: {
        your_name: '',
        contact_email: '',
        phone_number: '',
        app_to_integrate: '',
        integration_description: ''
      },
      selectedModule: {},
      mode: '',
      accountingProgam: [],
      modalUserInput: false,
    };
  },
  computed: {
    currentUsers() {
      return this.$store.getters['appConfig/currentUser'];
    },
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    moduleName() {
      return this.GET_LOCALE === 'sv' ? 'swe_name' : 'eng_name';
    },
    requestMessage() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    features() {
      return this.GET_LOCALE === 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  async mounted() {
    this.getPageAccess()
    await this.getModules()
    await this.getUsers()
    await this.getAccountingProgram()

    // Prefill
    if (this.currentUsers) {
      this.requestForm.your_name = this.currentUsers.full_name
      this.requestForm.contact_email = this.currentUsers.email
      this.requestForm.phone_number = this.currentUsers.phone
    }
    this.runClubInstance()
  },
  methods: {
    performModalAction() {
      this.sendingModalAction = true
      this.processRawModule(this.selectedModule, this.mode)
    },
    async getModules() {
      const currentUserModule = await this.getSubscriptions()

      const {data} = await this.getAllModules(this.queryParams)
      this.allModules = {
        ...data, results: data.results.map(el => {
          return {
            ...el,
            loadingStatus: false,
            subId: currentUserModule.data.data[0].uuid,
            currentModule: this.validateCurrentModule(currentUserModule, el)
          }
        })
      }
    },
    openModuleDetail(module) {
      this.selectedModule = module
      this.moduleModal = true
    },
    getPageAccess() {
      this.$useJwt.getAllModule({params: {}}).catch(err => {
        if (err.response.status === 403) {
          this.accessDenied = true
        } else {
          this.accessDenied = false
        }
      })
    },
    search: debounce(function (e) {
      if (e) {
        this.queryParams.search = e
      } else {
        delete this.queryParams.search
      }
      this.getModules()
    }, 350),
    validateCurrentModule(current, allModule) {
      const filter = current.data.data[0].modules.filter(el => el.uuid === allModule.uuid)
      if (filter.length > 0) {
        return true
      }
      return false
    },
    removeData(index, mode) {
      if (mode === 'USER') {
        this.selectedUsers.splice(index, 1)
      } else {
        this.selectedAccount.splice(index, 1)
      }
    },
    removeAccountData(index, mode, userIndex) {
      if (mode === 'USER') {
        let user = this.userForm[userIndex]
        user.selected.splice(index, 1)
        this.userForm[userIndex] = user
        // this.selectedUsers.splice(index, 1)
      } else {
        this.selectedAccount.splice(index, 1)
      }
    },
    addData(e, mode) {
      if (mode === 'USER') {
        const uuid = this.selectedUsers.filter(el => el.uuid === this.userSelect.uuid)
        if (uuid.length < 1) {
          this.selectedUsers.push(this.userSelect)
        }
        this.userSelect = ''
      } else {
        const uuid = this.selectedAccount.filter(el => el.uuid === this.accountSelect.uuid)
        if (uuid.length < 1) {
          this.selectedAccount.push(this.accountSelect)
        }
        this.accountSelect = ''
      }
    },
    addAccountData(e, mode, index) {
      if (mode === 'USER') {
        let userInfo = this.userForm[index]
        const uuid = userInfo.selected.filter(el => el.uuid === userInfo.select.uuid)
        if (uuid.length < 1) {
          userInfo.selected.push(userInfo.select)
        }
        userInfo.select = ''
        this.userForm[index] = userInfo
      } else {
        const uuid = this.selectedAccount.filter(el => el.uuid === this.accountSelect.uuid)
        if (uuid.length < 1) {
          this.selectedAccount.push(this.accountSelect)
        }
        this.accountSelect = ''
      }
    },
    getTranslatedName(item) {
      // return this.$t(this.message[])
    },
    getAccountingProgram() {
      this.$useJwt.getLandingPackages()
          .then(res => {
            const {accounting_programs} = res.data.data
            this.accountingProgam = accounting_programs
          })
    },
    requestIntegration() {
      this.$refs.form.validate().then(res => {
        if (res) {
          this.requesting = true

          this.$useJwt.requestIntegration(this.requestForm)
              .then(res => {
                this.requesting = false
                Object.keys(this.requestForm).forEach(el => this.requestForm[el] = '')
                this.showRequest = false
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.requestMessage],
                    'CheckIcon',
                    'success'
                );
              })
              .catch(err => {
                this.requesting = false
                if (err.response.data.error) {
                  this.popupMsg(
                      this.$t('Message.Failed'),
                      err.response.data.error,
                      'AlertTriangleIcon',
                      'danger'
                  );
                } else {
                  this.popupMsg(
                      this.$t('Message.Failed'),
                      err.response.data[this.requestMessage],
                      'AlertTriangleIcon',
                      'danger'
                  );
                }
              })
        }
      })
    },
    processRawModule(item, mode) {
      item.loadingStatus = true
      this.sendingModalAction = true
      let URLMode = {URL: '', method: 'put'}
      if (mode === 'ADD') {
        URLMode.URL = `/add_module/${item.subId}/`
      } else {
        URLMode.URL = `/remove_module/${item.subId}/`
      }

      this.$useJwt.customSubscription(URLMode, {
        module_uuid: item.uuid
      })
          .then(res => {
            item.loadingStatus = false
            this.sendingModalAction = false
            this.getModules()
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
            this.$bvModal.hide("activate-module-modal");
          })
          .catch(err => {
            item.loadingStatus = false
            this.sendingModalAction = false
            if (err.response.data.error) {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data.error,
                  'AlertTriangleIcon',
                  'danger'
              );
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    },
    processModal(mode) {
      this.processing = true
      let URLMode = {URL: '', method: 'put'}
      let payload = {}
      if (mode === 'ADD') {
        URLMode.URL = `/add_module/${this.selectedModule.subId}/`
        if (this.selectedModule.regarding_instance_type === 'ClubAccount') {
          // payload['club_account_details'] = this.selectedAccount.map(el => el)
          let account = []
          this.userForm.map(item => {
            account.push({
              name: item.name,
              number: item.number,
              accounting_program: item.accounting_program,
              user_uuids: item.selected.map(el => el.uuid)
            })
          })
          payload['club_account_details'] = account
          // payload['club_account_details'][0]['user_uuids'] = this.selectedUsers.map(el => el.uuid)
          // payload = this.form
          payload['module_uuid'] = this.selectedModule.uuid
        } else {
          let users = []
          this.selectedUsers.map(item => {
            // let obj = {
            //   uuid: item.uuid
            // }
            users.push(item)
          })
          payload['user_details'] = users
          payload['module_uuid'] = this.selectedModule.uuid
        }

      } else {
        URLMode.URL = `/remove_module/${this.selectedModule.subId}/`
        payload['module_uuid'] = this.selectedModule.uuid

        if (this.selectedModule.regarding_instance_type === 'User') {
          payload['resources_to_delete'] = this.selectedUsers.map(el => el.uuid)
        }
        if (this.selectedModule.regarding_instance_type === 'ClubAccount') {
          payload['resources_to_delete'] = this.selectedAccount.map(el => el.uuid)
        }
      }
      this.$useJwt.customSubscription(URLMode, payload)
          .then(res => {
            this.modalUserInput = false
            this.modalAddAccount = false
            this.clearForm()
            this.getModules()
            this.processing = false
            this.selectedUsers = []
            this.selectedAccount = []
            this.userForm = []

            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.processing = false
            if (err.response.data.error) {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data.error,
                  'AlertTriangleIcon',
                  'danger'
              );
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    },
    runClubInstance() {
      this.otherClubAccount = this.userData.club_accounts
      // if (this.userData.current_account) {
      //   const filter = this.userData.club_accounts.filter(el => el.uuid !== this.userData.current_account.uuid)
      //   if (filter.length > 0) {
      //
      //   }
      // }
    },
    getUsers() {
      this.$useJwt.getUsers().then(res => {
        this.accountUsers = res.data.data
      });
    },
    clearForm() {
      try {
        Object.keys(this.form).forEach(el => this.form[el] = '')
      } catch (e) {

      }
    },
    verifyModule(item, mode) {
      this.selectedModule = item
      this.mode = mode
      this.userForm = []
      if (item.needs_user_input) {
        if (mode === 'ADD') {
          if (item.regarding_instance_type === 'ClubAccount') {
            this.selectedModule['remove'] = false
            for (let j = 0; j < parseInt(item.extra_instances); j++) {
              this.userForm.push(JSON.parse(JSON.stringify(this.form)))
            }
            this.modalAddAccount = true
          } else {
            this.$bvModal.show("activate-module-modal");
            // this.processRawModule(item, mode)
          }
        } else {
          this.selectedModule['remove'] = true
          this.modalUserInput = true
          // if (item.regarding_instance_type === 'User') {
          //
          // } else{
          //
          // }
        }

      } else {
        this.processRawModule(item, mode)
      }
    }
  },

};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
